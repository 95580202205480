.bg-mh {
  background-color: #fff;
  margin-top: 20px;

  .paginating-button {
    background-color: #fff;
    border: 2px solid #00cded;
    border-radius: 4px;
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 2px;
    padding: 4px 8px 6px 8px;

    &:focus {
      outline: none;
    }
  }
}

.container-row{
  width: 100%;
  margin: 0px;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  max-width:100%;
}

.first-col{
  background: #00cded;
  color: #fff;
  font-weight: 700;
  padding: 8px 4px;
  left:-1px;
}

.default-col{
  padding: 8px 4px;
}

.header-col{
  background: #00cded;
  color: #fff;
  font-weight: 700;
  padding: 8px 4px;
  margin:1px;
  border-radius: 0.2rem;
}

.task-list-row-header{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 88%;
}

.update-logo{
  .file-uploader  { grid-area: 3 / 1 / 3 / 3; }
  .file-uploader {
    margin-top:1rem;
    margin-left: 10px;
    margin-right: 10px;
  }
  .custom-plus-div {
    display: flex;
  }
  .custom-plus-icon {
    background: url('../../assets/images/plus-solid.svg');
    background-size: 18px 18px;
    display: block;
    margin-top: 3px;
    width: 18px;
    height: 18px;
  }
}

.logo-image {
  height: 7rem;
  margin: 0 auto;
  display: block;
  margin-top: 1rem;
}